<template>
  <a href="https://docs.google.com/forms/d/e/1FAIpQLSeRPiQuWXpbG92w8p4yQ8st55YpNDsT0F6iF0l11SHGaSQ_XA/viewform"
    target="_blank" rel="noopener noreferrer"
    class="font-proxima font-bold inline-block text-black bg-coral-orange rounded-full" :class="[
      small ? 'px-4 py-2' : [
        'px-5 sm:px-6 md:px-10 xl:px-14 ',
        'py-2 sm:py-3 md:py-5 xl:py-7  ',
        'text-base sm:text-xl md:text-2xl xl:text-5xl '
      ]
    ]" v-html="buttonCont.join">
  </a>
</template>

<script>
import buttonCont from '../content/joinbutton.json'

export default {
  name: 'JoinButton',
  props: {
    small: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      buttonCont
    }
  }
}
</script>
