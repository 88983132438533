<template>
  <!-- Separator with angled path -->
  <div class="overflow-x-hidden p-0 m-0">
    <svg class="h-6" viewBox="0 0 1500 6">
      <path class="invisible sm:visible" d="M0,1 H27 V5 L31,1 H1500" stroke="black" stroke-width="1" fill="none" />
      <path class="visible sm:invisible" d="M0,1 H13 V5 L17,1 H1500" stroke="black" stroke-width="1" fill="none" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'TailSeparator'
}
</script>